.bar-container {
  display: flex;
  background-color: #d73c3c;
  color: white;
  font-family: UbuntuMono-Regular, serif;
  user-select: none;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
}

.button-container {
  display: flex;
}

.title-content {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: auto;
}

.icon {
  align-self: center;
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(253deg) brightness(103%) contrast(104%);
  width: 1.6em;
  height: 1.6em;
}

.button {
  display: flex;
  justify-content: center;
  border-radius: 0;
  width: 2.4em;
  height: 2.4em;
  background: none;
  border-width: 0.8px;
  border-color: #ea4c4c;
}

.button:hover {
  background: #ea4c4c;
}
